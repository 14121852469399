<template>
  <div class="sendMessage">
    <!-- <el-button type="primary" class="btn">新增</el-button> -->
    <div class="main_con">
      <div class="form search-area">
        <el-form
          :model="form"
          status-icon
          label-position="left"
          ref="form"
          label-width="120px"
          class="demo-form"
          :rules="rules"
        >
          <!-- 车辆 -->
          <el-form-item label="车辆" prop="checkedBus">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="form.checkAll"
              @change="allChange"
            >全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="form.checkedBus" @change="busChange">
              <el-checkbox
                v-for="bus in busOptions"
                :label="bus.vehicleNo"
                :key="bus.vehicleNo"
              >{{bus.cph}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <!-- 消息文本 -->
          <el-form-item label="消息文本" prop="radio">
            <el-select @change="changeRadio" v-model="form.radio">
              <el-option
                v-for="item in radioOptions"
                :key="item.detail"
                :label="item.name"
                :value="item.detail"
              ></el-option>
            </el-select>
            <!-- <el-radio-group>
              <el-radio
                v-for="(item,index) in radioOptions"
                :key="index"
                :label="item.content"
              >{{item.content}}</el-radio>
            </el-radio-group>-->
          </el-form-item>

          <!-- 编缉消息 -->
          <el-form-item label="编缉消息" prop="textarea">
            <el-input
              @input="input"
              style="margin-top:10px"
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
              v-model="form.textarea"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="sendMsg">发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { sendText, getSamelineAllbus } from "@/api/lib/bus-api.js";
import { queryMessageTemplateList } from "@/api/lib/api.js";
import moment from "moment";

export default {
  props: {
    lineId: {
      type: String
    }
  },
  created () {
    this.getLineId();
    this.getText();
  },

  data () {
    return {
      form: {
        lineId: null,
        checkedBus: [],
        checkAll: false,
        radio: null,
        textarea: ""
      },
      busOptions: [],
      radioOptions: [],
      isIndeterminate: false,
      rules: {
        lineId: [{ required: true, message: "请选择线路", trigger: "blur" }],
        checkedBus: [{ required: true, message: "请选择车辆", trigger: "blur" }]
      }
    };
  },

  methods: {
    sendMsg () {
      this.$refs.form.validate(valid => {
        if (valid) {
          sendText({
            vehicleNos: this.form.checkedBus,
            text: this.form.radio ? this.form.radio : this.form.textarea
          })
            .then(res => {
              if (res.code == 1000) {
                this.$message.success("发送成功");
                this.$emit("closeDia");
              }
            })
            .catch(error => { });
        }
      });
    },

    cancel () {
      this.$emit("closeDia");
    },

    getText () {
      queryMessageTemplateList().then(res => {
        this.radioOptions = res.data;
      });
    },

    getLineId () {
      getSamelineAllbus({ companyId: this.lineId, workTime: moment(new Date()).format("YYYY-MM-DD") }).then(res => {
        this.busOptions = res.data;
      });
    },

    allChange (val) {
      let arr = [];
      this.busOptions.map(item => {
        arr.push(item.vehicleNo);
      });
      this.form.checkedBus = val ? arr : [];
      this.isIndeterminate = false;
    },

    busChange (val) {
      let checkedCount = val.length;
      this.form.checkAll = checkedCount === this.busOptions.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.busOptions.length;
    },

    input () {
      this.form.radio = null;
    },

    changeRadio (text) {
      this.form.textarea = text;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.sendMessage {
  width: 100%;
  height: 100%;
  .main_con {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
  }
  .bottom {
    flex: 1;
    display: flex;
    .left_con {
      flex: 1;
    }
    .right_con {
      padding-left: 20px;
      flex: 3;
      border: none;
    }
  }

  .el-checkbox-group {
    .el-checkbox:first-child {
      margin-left: 0px;
    }
  }
}
</style>
