<template>
  <div class="schedulingPlatform">
    <el-tabs type="border-card" v-model="tabType" class="tab">
      <el-tab-pane
        v-for="(item,index) in tabOptions"
        :key="index"
        :label="item.alarmType"
        :name="item.alarmName"
      >
        <div style="position: absolute;">
          <el-button type="primary" size="small" @click="addTab(editableTabsValue)">添加线路</el-button>
        </div>
        <div v-if="tabType == '1'" class="schedulingTab">
          <el-tabs
            v-model="editableTabsValue"
            tab-position="left"
            type="card"
            closable
            @tab-remove="removeTab"
            @tab-click="clickTab"
          >
            <el-tab-pane
              v-for="ele in editableTabs"
              :key="ele.lineId"
              :label="ele.lineName"
              :name="ele.lineId"
            >
              <div class="schedule-information function-buttons">
                <div class="plan_div">
                  <!-- <div>
                    <span>{{realTime}}</span>
                  </div>-->
                  <div>
                    运营车辆：
                    <span>{{busNum}}</span>台
                  </div>
                  <div>
                    计划车次：
                    <span>{{totalNums}}</span>次
                  </div>
                  <div>
                    已完成：
                    <span>{{completedPlanNums}}</span>次
                  </div>
                  <div style="border-right:none">
                    未完成：
                    <span>{{incompletePlanNums}}</span>次
                  </div>
                </div>
                <div class="bottons_div">
                  <el-button
                    type="primary"
                    size="small"
                    class="btn"
                    @click="sendMessage"
                    v-if="$store.state.menu.nowMenuList.indexOf('消息下发') >= 0"
                  >消息下发</el-button>
                  <el-button
                    type="primary"
                    size="small"
                    class="btn"
                    @click="sendPlanText"
                    v-if="$store.state.menu.nowMenuList.indexOf('下发行车计划') >= 0"
                  >下发行车计划</el-button>
                  <el-button
                    type="primary"
                    size="small"
                    class="btn"
                    @click="changeDriver"
                    v-if="$store.state.menu.nowMenuList.indexOf('调整计划') >= 0"
                  >调整计划</el-button>
                  <el-button
                    type="primary"
                    size="small"
                    class="btn"
                    @click="changeAverage"
                    v-if="$store.state.menu.nowMenuList.indexOf('匀点') >= 0"
                  >匀点</el-button>
                  <el-button
                    size="small"
                    type="primary"
                    class="btn"
                    @click="addShiftOrTrip"
                    v-if="$store.state.menu.nowMenuList.indexOf('添加班次车次') >= 0"
                  >添加班次/车次</el-button>
                  <el-button
                    size="small"
                    type="primary"
                    class="btn"
                    @click="mapdrawer = true"
                    v-if="$store.state.menu.nowMenuList.indexOf('模拟地图') >= 0"
                  >模拟地图</el-button>
                  <el-button
                    size="small"
                    type="primary"
                    class="btn"
                    @click="onMonitorVehicleStatus"
                    v-if="$store.state.menu.nowMenuList.indexOf('监控车辆状态') >= 0"
                  >{{!drawer?'监控车辆状态':'取消监控车辆'}}</el-button>
                </div>
              </div>
              <div class="form-area">
                <el-form
                  :inline="true"
                  :model="form"
                  label-position="left"
                  class="demo-form-inline"
                  label-width="90px"
                >
                  <el-form-item label="发车类型:" prop="moveType">
                    <el-select
                      v-model="form.moveType"
                      placeholder="请选择发车类型"
                      @change="getCheCiListFn"
                      clearable
                    >
                      <el-option
                        v-for="i in moveTypes"
                        :key="i.value"
                        :label="i.label"
                        :value="i.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="车牌号:" prop="vehicleNo">
                    <el-select
                      v-model="form.vehicleNo"
                      placeholder="请选择车牌号"
                      @change="getCheCiListFn"
                      clearable
                    >
                      <el-option
                        v-for="i in allBusByline"
                        :key="i.vehicleNo"
                        :label="i.cph"
                        :value="i.vehicleNo"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
              <el-table
                class="basicBox dispatchMonitoring"
                :data="checiNowList"
                @selection-change="getCheckedData"
                height="65vh"
                stripe
                :cell-style="{color:'#000',fontSize:'16px'}"
                :header-cell-style="{ background: '#fff', color: '#999999' }"
                v-el-table-infinite-scroll="load"
                :infinite-scroll-disabled="loading"
                infinite-scroll-distance="20"
              >
                <el-table-column
                  type="selection"
                  width="55"
                  :selectable="checkboxT"
                  disabled="true"
                ></el-table-column>
                <el-table-column prop="moveType" label="发车类型">
                  <template
                    slot-scope="scope"
                  >{{ moveTypes.find(t => t.value === scope.row.moveType).label }}</template>
                </el-table-column>
                <el-table-column prop="beginTime" label="计划发车"></el-table-column>
                <el-table-column prop="leaveTime" label="实际发车"></el-table-column>
                <el-table-column prop="endTime" label="计划到达"></el-table-column>
                <el-table-column prop="arriveTime" label="实际到达"></el-table-column>
                <el-table-column prop="workTime" label="行驶时长"></el-table-column>
                <el-table-column prop="cph" label="车牌号" width="110">
                  <template slot-scope="scope">
                    <span
                      style="color:#01ADFF;cursor:pointer"
                      @click="showScheduling(scope.row)"
                    >{{scope.row.cph}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="driverName" label="计划司机"></el-table-column>
                <el-table-column prop="realityDriverName" label="实际司机"></el-table-column>
                <el-table-column prop="busStatus" label="行车状态">
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.busStatus == '0'"
                      style="color:#FF1212"
                    >{{scope.row.busStatus | filterBusStatus}}</span>
                    <span
                      v-if="scope.row.busStatus == '1'"
                      style="color:#FFCF0A"
                    >{{scope.row.busStatus| filterBusStatus}}</span>
                    <span
                      v-if="scope.row.busStatus == '2'"
                      style="color:#0053E1"
                    >{{scope.row.busStatus| filterBusStatus}}</span>
                    <span
                      v-if="scope.row.busStatus == '3'"
                      style="color:#2BC505"
                    >{{scope.row.busStatus| filterBusStatus}}</span>
                    <span
                      v-if="scope.row.busStatus  == '4'"
                    >{{scope.row.busStatus| filterBusStatus}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="stationDesc"
                  label="当前位置"
                  width="180"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.adjustStatus == 0" style="color: red">
                      {{
                      scope.row.adjustStatus | filterAdjustStatus
                      }}
                    </span>
                    <span v-else>{{scope.row.adjustStatus | filterAdjustStatus}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="natureState" label="变更说明">
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.natureState == '3' || scope.row.natureState == '4' || scope.row.natureState == '5'"
                      style="color:#0053e1"
                    >{{ (natureOptions.find(t => t.dictCode === scope.row.natureState)||{dictValue:""}).dictValue }}</span>
                    <span
                      v-if="scope.row.natureState == '6'"
                      style="color:#acb2bc"
                    >{{ (natureOptions.find(t => t.dictCode === scope.row.natureState)||{dictValue:""}).dictValue }}</span>
                    <span
                      v-if="scope.row.natureState == '7'"
                      style="color:#2dbff7"
                    >{{ (natureOptions.find(t => t.dictCode === scope.row.natureState)||{dictValue:""}).dictValue }}</span>
                    <span
                      v-if="scope.row.natureState == '0' || scope.row.natureState == '128'"
                    >{{ (natureOptions.find(t => t.dictCode === scope.row.natureState)||{dictValue:""}).dictValue }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-if="tabType == '2' ">
          <el-tabs
            v-model="editableTabsValue"
            tab-position="left"
            type="card"
            closable
            @tab-remove="removeTab"
            @tab-click="clickTab"
          >
            <el-tab-pane
              v-for="ele in editableTabs"
              :key="ele.lineId"
              :label="ele.lineName"
              :name="ele.lineId"
            >
              <div class="form-area">
                <el-form
                  :inline="true"
                  :model="eventform"
                  label-position="left"
                  class="demo-form-inline"
                >
                  <!-- <el-form-item label="请求时间" prop="timeRange">
                    <el-date-picker
                      v-model="eventform.timeRange"
                      :clearable="false"
                      :editable="false"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      type="daterange"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>-->
                  <el-form-item label="开始时间：" prop="startTime">
                    <el-date-picker
                      v-model="eventform.startTime"
                      :clearable="false"
                      :editable="false"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="结束时间：" prop="endTime">
                    <el-date-picker
                      v-model="eventform.endTime"
                      :clearable="false"
                      :editable="false"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="业务类型：" prop="eventId">
                    <el-select v-model="eventform.eventId" placeholder="请选择业务类型" clearable>
                      <el-option
                        v-for="i in natureOptions"
                        :key="i.dictCode"
                        :label="i.dictValue"
                        :value="i.dictCode"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="车辆：" prop="vehicleNo">
                    <el-select v-model="eventform.vehicleNo" placeholder="请选择车牌号" clearable>
                      <el-option
                        v-for="i in allBusByline"
                        :key="i.vehicleNo"
                        :label="i.cph"
                        :value="i.vehicleNo"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="处理状态：" prop="opStats">
                    <el-select v-model="eventform.opStats" placeholder="请选择处理状态" clearable>
                      <el-option label="已同意" value="1"></el-option>
                      <el-option label="已拒绝" value="2"></el-option>
                      <el-option label="未处理" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label prop="busId">
                    <el-button type="primary" size="small" class="btn" @click="searchEvent">查询</el-button>
                  </el-form-item>
                </el-form>
              </div>

              <el-table
                class="basicBox"
                :data="eventList"
                @row-click="showEventUndeal"
                height="63vh"
                stripe
                :header-cell-style="{ background: '#fff', color: '#999999' }"
              >
                <el-table-column type="index" label="序号" width="100"></el-table-column>
                <el-table-column prop="insertTime" label="请求时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="cph" label="车辆"></el-table-column>
                <el-table-column prop="driverName" label="司机"></el-table-column>
                <el-table-column prop="eventId" label="请求业务">
                  <template
                    slot-scope="scope"
                  >{{ (natureOptions.find(t => t.dictCode == scope.row.eventId)||{dictValue:""}).dictValue }}</template>
                </el-table-column>
                <el-table-column prop="opStats" label="处理状态">
                  <template slot-scope="scope">{{scope.row.opStats | fileterOpstats}}</template>
                </el-table-column>
                <el-table-column prop="updateTime" label="处理时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="operatorName" label="处理人"></el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 弹框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" v-if="dialogVisible" width="50%">
      <sendMsg :lineId="editableTabsValue" @closeDia="closeDia"></sendMsg>
    </el-dialog>

    <el-dialog
      title="排班匀点"
      :visible.sync="averagdialogVisible"
      v-if="averagdialogVisible"
      width="50%"
    >
      <div class="form search-area">
        <el-form
          :model="averageform"
          status-icon
          :rules="averagerules"
          label-position="left"
          ref="averageform"
          label-width="102px"
          class="demo-form dialog-form"
        >
          <el-form-item label="匀点方向" prop="direction" style="width:400px">
            <el-select v-model="averageform.direction" placeholder="请选择方向">
              <el-option label="向前" value="1"></el-option>
              <el-option label="向后" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="匀点数" prop="spotCount" style="width:400px">
            <el-input v-model="averageform.spotCount"></el-input>
            <span class="numberNotice">操作说明：匀点车次相邻车次的匀点数为1，以此类推匀点数递增</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="averagdialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveaverage">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="计划调整"
      :visible.sync="driverdialogVisible"
      v-if="driverdialogVisible"
      width="50%"
    >
      <div class="form search-area">
        <el-form
          :model="updateform"
          status-icon
          :rules="updaterules"
          label-position="left"
          ref="updateform"
          label-width="102px"
          class="demo-form dialog-form"
        >
          <el-form-item label prop="type">
            <el-radio
              v-model="updateform.type"
              label="1"
              :disabled="updateform.cheCiIds.length > 1"
            >调整时间</el-radio>
            <el-radio
              v-model="updateform.type"
              label="2"
              :disabled="updateform.cheCiIds.length > 1"
            >顶班</el-radio>
            <el-radio v-model="updateform.type" label="3">删除计划</el-radio>
          </el-form-item>

          <div v-if="updateform.type  === '1'">
            <el-form-item label="发车时间:" prop="beginTime">
              <el-time-picker
                format="HH:mm"
                value-format="HH:mm"
                size="small"
                v-model="updateform.beginTime"
                placeholder="选择时间"
              ></el-time-picker>
            </el-form-item>
          </div>

          <div v-if="updateform.type  === '2'">
            <el-form-item label="方式" prop="classType">
              <el-select
                v-model="updateform.classType"
                placeholder="请选择方式"
                @change="checBusDriverByline"
              >
                <el-option label="车人并换" value="1"></el-option>
                <el-option label="司机顶班" value="2"></el-option>
                <el-option label="车辆顶班" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="现车辆" prop="oldVehicleNo">
              <el-select :disabled="true" v-model="updateform.oldVehicleNo">
                <el-option
                  v-for="item in nowBusByline"
                  :key="item.vehicleNo"
                  :label="item.cph"
                  :value="item.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="现司机" prop="oldCredentialsNo">
              <el-select :disabled="true" v-model="updateform.oldCredentialsNo">
                <el-option
                  v-for="item in nowDriverByline"
                  :key="item.credentialsNo"
                  :label="item.driverName"
                  :value="item.credentialsNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="顶班车辆" v-if="updateform.classType != '2'" prop="newVehicleNo">
              <el-select :disabled="updateform.classType == '2'" v-model="updateform.newVehicleNo">
                <el-option
                  v-for="item in allDingBusByline"
                  :key="item.vehicleNo"
                  :label="item.cph"
                  :value="item.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="顶班司机" v-if="updateform.classType != '3'" prop="newCredentialsNo">
              <el-select v-model="updateform.newCredentialsNo">
                <el-option
                  v-for="item in allDriverByline"
                  :key="item.credentialsNo"
                  :label="item.driverName"
                  :value="item.credentialsNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车次" prop="checi" style="width:600px">
              <el-table
                class="basicBox"
                :data="dingAllList"
                @selection-change="getDingCheckedData"
                stripe
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="beginTime" label="计划发车"></el-table-column>
                <el-table-column prop="endTime" label="计划到达">
                  <template slot-scope="scope">{{scope.row | filterDingArrive}}</template>
                </el-table-column>
                <el-table-column prop="moveType" label="发车类型"></el-table-column>
              </el-table>
            </el-form-item>
          </div>

          <div v-if="updateform.type  === '3'">
            <el-form-item label="变更说明:" prop="natureState">
              <el-select v-model="updateform.natureState" placeholder="请选择变更说明">
                <el-option
                  v-for="item in natureOptions"
                  :key="item.dictCode"
                  :label="item.dictValue"
                  :value="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input v-model="updateform.remark"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="driverdialogVisible = false">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          v-if="updateform.type  === '1'"
          @click="changeTime"
        >确 定</el-button>
        <el-button size="small" type="primary" v-if="updateform.type  === '2'" @click="update">保存</el-button>
        <el-button
          size="small"
          type="primary"
          v-if="updateform.type  === '3'"
          @click="submitDelPlan"
        >确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加班次/车次 -->
    <el-dialog
      title="添加班次/车次"
      :visible.sync="addShiftOrTripDia"
      width="50%"
      class="addShiftOrTripDia"
      @close="cancleAddShiftOrTripDia"
      @open="addShiftOrTripDia = true"
    >
      <el-tabs type="border-card" v-model="addShiftOrTripRadio" @tab-click="shift2radio">
        <el-tab-pane label="添加班次" name="1">
          <div class="form search-area">
            <el-form
              ref="form"
              :model="addShiftOrTripForm"
              label-position="left"
              label-width="102px"
              class="demo-form dialog-form"
              :rules="addShiftOrTripRules"
            >
              <el-form-item label="线路:" prop="line">
                <el-select v-model="addShiftOrTripForm.line" placeholder="请选择" @change="chooseLine">
                  <el-option
                    v-for="item in allLines"
                    :key="item.companyId"
                    :label="item.companyName"
                    :value="item.companyId"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="车辆:" prop="vehicle">
                <el-select
                  filterable
                  :filter-method="filterMethodFun"
                  v-model="addShiftOrTripForm.vehicle"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in vehicleOptions"
                    :key="item.vehicleNo"
                    :label="item.cph"
                    :value="item.vehicleNo"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="驾驶员:" prop="vehicle">
                <el-select
                  filterable
                  :filter-method="vehicleMethodFun"
                  v-model="addShiftOrTripForm.driver"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in driversOptions"
                    :key="item.credentialsNo"
                    :label="item.driverName"
                    :value="item.credentialsNo"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="发车类型:" prop="type">
                <el-radio v-model="addShiftOrTripForm.type" label="1">主-副</el-radio>
                <el-radio v-model="addShiftOrTripForm.type" label="2"  v-if="LineType!== 1">副-主</el-radio>
              </el-form-item>

              <el-form-item label="发车时间:" prop="time">
                <el-time-picker
                  v-model="addShiftOrTripForm.time"
                  value-format="HH:mm"
                  placeholder="请选择发车时间"
                ></el-time-picker>
              </el-form-item>

              <el-form-item label="运行时长(分钟):" prop="runTime" ref="runTime">
                <el-input v-model="addShiftOrTripForm.runTime"></el-input>
              </el-form-item>

              <el-form-item label="休息时长(分钟):" prop="restTime" ref="restTime">
                <el-input v-model="addShiftOrTripForm.restTime"></el-input>
              </el-form-item>

              <el-form-item label="增加趟次:" v-if="addShiftOrTripRadio == 1" prop="addTrips">
                <el-select v-model="addShiftOrTripForm.addTrips" placeholder="请选择">
                  <el-option
                    v-for="item in addTripsOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="添加车次" name="2">
          <div class="form search-area">
            <el-form
              ref="form"
              :model="addShiftOrTripForm"
              label-position="left"
              label-width="102px"
              class="demo-form dialog-form"
              :rules="addShiftOrTripRules"
            >
              <el-form-item label="线路:" prop="line">
                <el-select v-model="addShiftOrTripForm.line" placeholder="请选择" @change="chooseLine">
                  <el-option
                    v-for="item in allLines"
                    :key="item.companyId"
                    :label="item.companyName"
                    :value="item.companyId"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="车辆:" prop="vehicle">
                <el-select
                  filterable
                  :filter-method="filterMethodFun"
                  v-model="addShiftOrTripForm.vehicle"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in vehicleOptions"
                    :key="item.vehicleNo"
                    :label="item.cph"
                    :value="item.vehicleNo"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="驾驶员:" prop="vehicle">
                <el-select
                  filterable
                  :filter-method="vehicleMethodFun"
                  v-model="addShiftOrTripForm.driver"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in driversOptions"
                    :key="item.credentialsNo"
                    :label="item.driverName"
                    :value="item.credentialsNo"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="发车类型:" prop="type">
                <el-radio v-model="addShiftOrTripForm.type" label="1">主-副</el-radio>
                <el-radio v-model="addShiftOrTripForm.type" label="2" v-if="LineType !== 1">副-主</el-radio>
              </el-form-item>

              <el-form-item label="发车时间:" prop="time">
                <el-time-picker
                  v-model="addShiftOrTripForm.time"
                  value-format="HH:mm"
                  placeholder="请选择发车时间"
                ></el-time-picker>
              </el-form-item>

              <el-form-item label="运行时长(分钟):" prop="runTime" ref="runTime">
                <el-input v-model="addShiftOrTripForm.runTime"></el-input>
              </el-form-item>

              <el-form-item label="休息时长(分钟):" prop="restTime" ref="restTime">
                <el-input v-model="addShiftOrTripForm.restTime"></el-input>
              </el-form-item>

              <el-form-item label="增加趟次:" v-if="addShiftOrTripRadio == 1" prop="addTrips">
                <el-select v-model="addShiftOrTripForm.addTrips" placeholder="请选择">
                  <el-option
                    v-for="item in addTripsOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="buttonGroup text-right tabButtonGroup">
        <el-button type="default" size="small" @click="cancleAddShiftOrTripDia">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitShiftOrTrip"
          :disabled="!isSubmitShiftOrTripVisible"
        >确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="单车发班表" :visible.sync="schedulingVisible" v-if="schedulingVisible" width="30%">
      <el-table :data="busPlanList">
        <el-table-column prop="time" :label="busCode">
          <el-table-column
            :prop="banCiMoveType == 2 ? 'down':'up'"
            :label="banCiMoveType == 2 ? '主-副':'副-主'"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.downState ===1 && banCiMoveType == 2"
                style="color:rgb(1, 173, 255)"
              >{{scope.row.down}}</span>
              <span v-if="scope.row.downState ===0 && banCiMoveType == 2">{{scope.row.down}}</span>
              <span
                v-if="scope.row.upState ===1 && banCiMoveType == 1"
                style="color:rgb(1, 173, 255)"
              >{{scope.row.up}}</span>
              <span v-if="scope.row.upState ===0 && banCiMoveType == 1">{{scope.row.up}}</span>
            </template>
          </el-table-column>
          <el-table-column
            :prop="banCiMoveType == 2 ? 'up':'down'"
            :label="banCiMoveType == 2 ? '副-主':'主-副'"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.downState ===1 && banCiMoveType == 1"
                style="color:rgb(1, 173, 255)"
              >{{scope.row.down}}</span>
              <span v-if="scope.row.downState ===0 && banCiMoveType == 1">{{scope.row.down}}</span>
              <span
                v-if="scope.row.upState ===1 && banCiMoveType == 2"
                style="color:rgb(1, 173, 255)"
              >{{scope.row.up}}</span>
              <span v-if="scope.row.upState ===0 && banCiMoveType == 2">{{scope.row.up}}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="schedulingVisible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="新增关注线路" :visible.sync="followVisible" v-if="followVisible" width="30%">
      <div class="form search-area">
        <el-form
          :model="form"
          :rules="rules"
          label-position="left"
          ref="form"
          label-width="102px"
          class="demo-form dialog-form"
        >
          <el-form-item label="线路" prop="lineId">
            <el-select v-model="form.lineId" placeholder="请选择线路">
              <el-option
                v-for="item in allLines"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="followVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveFollowLine">保存</el-button>
      </div>
    </el-dialog>

    <el-drawer
      v-if="mapdrawer"
      :visible.sync="mapdrawer"
      :with-header="false"
      :custom-class="'drawer'"
      :modal="false"
      direction="btt"
      :size="'50%'"
      @close="closeDrawer"
      @open="openDrawer"
    >
      <div class="content">
        <simulationMap style="margin-top:7vh"
        :isAllSimulate="true" :lineId="querryLineId"></simulationMap>
      </div>
    </el-drawer>

    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      :custom-class="'drawer'"
      :modal="false"
      :size="'20%'"
      @close="closeDrawer"
      @open="openDrawer"
    >
      <div class="content">
        <div class="vehicle-status">
          <el-table :data="vehicleStatusData" class="status-table" :cell-style="setCellStyle">
            <el-table-column label="颜色" width="100">
              <template slot-scope="scope" v-if="scope.row.color">
                <i
                  :class="{'status-color':true,'normal':scope.$index==0,'offline':scope.$index==1,'maintain':scope.$index==2,'refill':scope.$index==3,'outline':scope.$index==4}"
                ></i>
                <span style="margin-left: 10px" v-if="scope.$index==0">正常</span>
                <span style="margin-left: 10px" v-if="scope.$index==1">离线</span>
                <span style="margin-left: 10px" v-if="scope.$index==2">维修</span>
                <span style="margin-left: 10px" v-if="scope.$index==3">加气</span>
                <span style="margin-left: 10px" v-if="scope.$index==4">脱线</span>
              </template>
            </el-table-column>
            <el-table-column label="车辆状态">
              <el-table-column label="主发" prop="master.cph"></el-table-column>
              <el-table-column label="副发" prop="slave.cph"></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  saveFollowLine,
  deleteFollowLine,
  getFollowLineList,
  queryAllBusLineList,
  queryTopShiftDriverDispatch,
  queryTopShiftVehicleDispatch,
  getSamelineAllbus,
  getSamelineAlldriver,
  getBusByline,
  getDriverByline,
  getCheCiPlatform,
  // restoreCheci,
  topShiftDispatch,
  deleteCheCiByIds,
  updateWorkTripTime,
  getBusPlanList,
  averageSpot,
  queryTopShiftChiCiDispatch,
  sendPlanText,
  getPlanStatisticsByLineId,
  getEventReport,
  getBusStatus,
  addBanciDispatch,
  addCheciDispatch
} from "@/api/lib/bus-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import moment from "moment";
import sendMsg from "./sendMessage";
import simulationMap from "../simulateMap/simulateMap.vue";
import { formatDay } from "@/common/utils/index";
import elTableInfiniteScroll from "el-table-infinite-scroll";

export default {
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll
  },
  components: {
    sendMsg,
    simulationMap
  },
  watch: {
    "$store.state.system.closeEvent"(curVal, oldVal) {
      if (curVal) {
        getEventReport(this.eventform).then(res => {
          this.eventList = res.data;
        });
      } else {
      }
    }
  },
  computed:{
    querryLineId(){
      return Number(this.editableTabsValue)
    }
  },
  data() {
    return {
      showTabType: true,
      tabType: "1",
      tabOptions: [
        { alarmType: "调度监控", alarmName: "1" },
        { alarmType: "车辆请求处理", alarmName: "2" }
      ],
      editableTabsValue: null,
      editableTabs: [],
      timer: null,
      cheTimer: null,
      loading: false,
      completedPlanNums: 0,
      incompletePlanNums: 0,
      totalNums: 0,
      busNum: 0,
      realTime: null,
      form: {
        lineId: null,
        vehicleNo: null,
        moveType: null,
        workTime: moment(new Date()).format("YYYY-MM-DD")
      },
      tripTimeArr: [],
      updateform: {
        lineId: null,
        oldCredentialsNo: null,
        oldVehicleNo: null,
        newCredentialsNo: null,
        newVehicleNo: null,
        workDay: null,
        classType: "1",
        updateType: null,
        type: "1",
        beginTime: null,
        natureState: null,
        remark: null,
        cheCiIds: []
      },
      averageform: {
        direction: "2",
        cheCiIdSelected: null,
        spotCount: null
      },
      eventform: {
        lineId: null,
        eventId: null,
        vehicleNo: null,
        opStats: null,
        timeRange: [formatDay(new Date()), formatDay(new Date())],
        startTime: formatDay(new Date()),
        endTime: formatDay(new Date()),
        begin: null,
        end: new Date()
      },
      moveTypes: [
        { value: 1, label: "副-主" },
        { value: 2, label: "主-副" },
        { value: null, label: "全部" }
      ],
      natureOptions: [],
      dingCheCiIds: [],
      allLines: [],
      busPlanList: [],
      checiAllList: [],
      checiNowList: [],
      dingAllList: [],
      allBusByline: [],
      allDingBusByline: [],
      eventList: [],
      nowBusByline: [],
      nowDriverByline: [],
      allDriverByline: [],
      followVisible: false,
      dialogVisible: false,
      schedulingVisible: false,
      averagdialogVisible: false,
      driverdialogVisible: false,
      driverName: "",
      busCode: "",
      banCiMoveType: null,
      vehicleOptions: [],
      driversOptions: [],
      rules: {
        lineId: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      updaterules: {
        lineId: [{ required: true, message: "不能为空", trigger: "change" }],
        oldCredentialsNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        oldVehicleNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        newCredentialsNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        classType: [{ required: true, message: "不能为空", trigger: "change" }],
        newVehicleNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ]
      },
      averagerules: {
        spotCount: [
          { required: true, message: "不能为空", trigger: "change" },
          {
            validator(rule, value, callback) {
              if (/(^[1-9]\d*$)/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正整数"));
              }
            },
            trigger: "blur"
          }
        ],
        cheCiIdSelected: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        direction: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      monitorVehicleStatus: true, // 是否监控车辆状态
      drawer: false, // 是否打开抽屉
      mapdrawer: false,
      vehicleStatusData: [], // 车辆状态表格数据

      pickerOptions: {
        selectableRange: "00:00:00 - 23:59:59"
      },
      addShiftOrTripDia: false, // 添加车次/班次弹窗
      isSubmitShiftOrTripVisible: false,
      addShiftOrTripRadio: "1",
      addShiftOrTripForm: {
        line: "",
        date: "",
        vehicle: "",
        type: "1",
        time: "",
        runTime: "",
        restTime: "",
        addTrips: "0.5",
        driver: ""
      },
      addShiftOrTripRules: {
        runTime: [
          {
            validator(rule, value, callback) {
              if (/(^[0-9]\d*$)/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入大于等于0的整数"));
              }
            },
            trigger: "change"
          }
        ]
      },
      addTripsOptions: [
        { value: 0.5, label: "0.5" },
        { value: 1, label: "1" },
        { value: 1.5, label: "1.5" },
        { value: 2, label: "2" },
        { value: 2.5, label: "2.5" },
        { value: 3, label: "3" },
        { value: 3.5, label: "3.5" },
        { value: 4, label: "4" },
        { value: 4.5, label: "4.5" },
        { value: 5, label: "5" }
      ],
      LineType:1,
    };
  },

  methods: {
    
    vehicleMethodFun(text) {
      getDriverByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
      });
    },
    // 车辆模糊查询
    filterMethodFun(text) {
      getBusByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.vehicleOptions = res.data;
        }
      });
    },
    clickTab() {
      this.getSamelineAllbus();
      this.getCheCiListFn();
      this.getStatistic();
    },

    //新增关注线路
    addTab() {
      this.getAllLineListFn();
      this.followVisible = true;
    },

    //新增关注线路确定按钮
    saveFollowLine() {
      this.$refs.form.validate(valid => {
        if (valid) {
          saveFollowLine({ lineId: this.form.lineId })
            .then(res => {
              this.$message.success("操作成功");
              this.followVisible = false;
              this.getFollowLineList(0);
            })
            .catch(error => {});
        }
      });
    },

    //移除关注线路
    removeTab(lineId) {
      this.$confirm("是否删除关注线路?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteFollowLine({ lineId: lineId }).then(res => {
            this.$message.success("操作成功");
            this.followVisible = false;
            this.getFollowLineList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    //查询已关注线路
    getFollowLineList(type) {
      getFollowLineList().then(res => {
        if (res.data.length != 0) {
          res.data.forEach(element => {
            element.lineId = element.lineId + "";
          });
          this.editableTabs = res.data;
          if (type == 0) {
            this.editableTabsValue = this.form.lineId + "";
          } else {
            this.editableTabsValue = res.data[0].lineId;
          }
          this.getStatistic();
          this.getSamelineAllbus();
          this.getCheCiListFn();
          if (this.cheTimer == null) {
            this.cheTimer = setInterval(() => {
              this.getCheCiListFn();
            }, 20000);
          }
        } else {
          this.editableTabs = [];
          this.editableTabsValue = "";
        }
      });
    },

    //根据线路查询车辆
    getSamelineAllbus() {
      this.form.vehicleNo = null;
      this.form.moveType = null;
      getSamelineAllbus({
        companyId: this.editableTabsValue,
        isArranging: 1,
        workTime: this.form.workTime
      }).then(res => {
        this.allBusByline = res.data;
      });
    },

    //指定线路获取所有司机请求
    searchEvent() {
      if (this.eventform.timeRange == null) {
        this.$message.warning("请选择日期");
      } else {
        this.eventform.lineId = this.editableTabsValue;
        this.eventform.begin = this.eventform.startTime;
        this.eventform.end = this.eventform.endTime;

        // this.eventform.begin = this.eventform.timeRange[0];
        // this.eventform.end = this.eventform.timeRange[1];
        getEventReport(this.eventform).then(res => {
          this.eventList = res.data;
        });
      }
    },

    showEventUndeal(row) {
      this.$store.dispatch("eventId", row);
    },

    //获取计划信息
    getStatistic() {
      getPlanStatisticsByLineId({ companyId: this.editableTabsValue }).then(
        res => {
          this.realTime = moment(new Date()).format("YYYY-MM-DD HH:mm");
          if (res.data) {
            this.completedPlanNums = res.data.completedPlanNums;
            this.busNum = res.data.busNum;
            this.newPlanNums = res.data.newPlanNums;
            this.totalNums = res.data.totalNums;
            this.incompletePlanNums = res.data.incompletePlanNums;
          } else {
            this.completedPlanNums = 0;
            this.busNum = 0;
            this.newPlanNums = 0;
            this.totalNums = 0;
            this.incompletePlanNums = 0;
          }
        }
      );
    },

    //发送消息
    sendMessage() {
      this.dialogVisible = true;
      this.title = "发送消息";
    },

    //下发行车计划
    sendPlanText() {
      if (this.updateform.cheCiIds.length > 0) {
        this.$confirm("车辆将收到最新的行车计划，是否下发?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            sendPlanText({ checiIds: this.updateform.cheCiIds }).then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消下发行车计划"
            });
          });
      } else {
        this.$message.warning("请至少勾选1个点进行下发行车计划！");
      }
    },

    // 关闭消息下发弹窗
    closeDia() {
      this.dialogVisible = false;
    },

    // 选中checkbox
    getCheckedData(val) {
      if (val.length == 1) {
        this.updateform.oldCredentialsNo = val[0].credentialsNo;
        this.updateform.oldVehicleNo = val[0].vehicleNo;
        this.updateform.beginTime = val[0].beginTime;
        this.nowBusByline = [{ cph: val[0].cph, vehicleNo: val[0].vehicleNo }];
        this.nowDriverByline = [
          { driverName: val[0].driverName, credentialsNo: val[0].credentialsNo }
        ];
      }
      this.updateform.cheCiIds = val.map(ele => ele.id);
    },

    // 顶班选中checkbox
    getDingCheckedData(val) {
      this.dingCheCiIds = val.map(ele => ele.cheCiId);
    },

    //禁用计划状态为删除的复选框
    checkboxT(row, index) {
      if (row.adjustStatus === "删除") {
        return false;
      } else {
        return true;
      }
    },

    // 恢复删除
    resumeDelete(id) {
      this.$confirm("是否恢复删除的车次?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          restoreCheci({ cheCiId: id }).then(res => {
            this.$message.success("操作成功");
            this.getCheCiListFn();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消恢复"
          });
        });
    },

    exportFile() {
      if (this.total > 0) {
        exportAutoScheduling({
          lineId: this.editableTabsValue,
          chooseDate: this.form.workTime,
          banciId: this.form.banCiId
        });
      } else {
        this.$message.warning("暂无数据可以导出");
      }
    },

    update() {
      var self = this;
      self.$refs.updateform.validate(valid => {
        if (valid) {
          self.updateform.workDay = self.form.workTime;
          self.updateform.cheCiIds = self.dingCheCiIds;
          var data = {
            cheCiIds: self.updateform.cheCiIds,
            classType: self.updateform.classType,
            newVehicleNo: self.updateform.newVehicleNo,
            newCredentialsNo: self.updateform.newCredentialsNo,
            workDay: self.updateform.workDay
          };
          topShiftDispatch(data)
            .then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
                this.getCheCiListFn();
                this.driverdialogVisible = false;
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },

    // 调整时间
    changeTime() {
      updateWorkTripTime({
        newBeginTime: this.updateform.beginTime,
        cheCiId: this.updateform.cheCiIds[0]
      })
        .then(res => {
          if (res.code === 1000) {
            this.$message.success("操作成功");
            this.getCheCiListFn();
            this.driverdialogVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // 保存匀点
    saveaverage() {
      var self = this;
      self.$refs.averageform.validate(valid => {
        if (valid) {
          averageSpot(self.averageform)
            .then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
                this.averagdialogVisible = false;
                this.getCheCiListFn();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },

    // 打开匀点弹窗
    changeAverage() {
      if (this.updateform.cheCiIds.length == 1) {
        if (this.form.moveType == null) {
          this.$message.warning("班次类型不能选择全部！");
        } else {
          this.averagdialogVisible = true;
          this.averageform.cheCiIdSelected = this.updateform.cheCiIds[0];
        }
      } else {
        this.$message.warning("请勾选1个点进行匀点！");
      }
    },

    // 打开调整计划弹窗
    changeDriver() {
      if (this.updateform.cheCiIds.length > 1) {
        this.updateform.type = "3";
      } else if (this.updateform.cheCiIds.length === 1) {
        this.updateform.type = "1";
      } else {
        this.$message.warning("请至少勾选1个点进行调整计划！");
        return;
      }
      this.updateform.lineId = this.editableTabsValue * 1;
      this.updateform.updateType = 0;
      this.updateform.newCredentialsNo = null;
      this.updateform.newVehicleNo = null;
      this.checBusDriverByline();
      this.getDingListFn();
      this.getStatistic();
      this.driverdialogVisible = true;
    },
    // 打开单车发班表弹窗
    showScheduling(row) {
      let self = this;
      let data = {
        vehicleNo: row.vehicleNo,
        planType: 2,
        workDay: self.form.workTime
      };
      getBusPlanList(data)
        .then(res => {
          self.busPlanList = [];
          self.busCode = res.data.cph;
          self.banCiMoveType = res.data.banCiMoveType;
          // self.busPlanList = res.data.planList;
          res.data.planList.forEach(item => {
            if (!(item.down == "" && item.up == "")) {
              self.busPlanList.push(item);
            }
          });
          self.schedulingVisible = true;
        })
        .catch(error => {});
    },

    // 删除计划确定按钮
    submitDelPlan() {
      var self = this;
      deleteCheCiByIds({
        cheCiIds: this.updateform.cheCiIds,
        natureState: this.updateform.natureState,
        remark: this.updateform.remark
      })
        .then(res => {
          if (res.code === 1000) {
            this.$message.success("操作成功");
            this.driverdialogVisible = false;
            this.getCheCiListFn();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // 所有的线路下拉菜单
    getAllLineListFn() {
      var self = this;
      queryAllBusLineList({}).then(res => {
        if (res.data.length == 0) {
          self.$message.warning("还没有线路信息，请先添加线路信息");
        } else {
          self.allLines = res.data;
        }
      });
    },

    // 显示车次列表
    getCheCiListFn() {
      var self = this;
      var data = {
        companyId: self.editableTabsValue,
        workTime: self.form.workTime,
        isArranging: self.$route.query.fromSch == 1 ? 1 : 0,
        moveType: self.form.moveType,
        vehicleNo: self.form.vehicleNo
      };
      getCheCiPlatform(data).then(res => {
        self.checiNowList = res.data.splice(0, 20);
        self.checiAllList = res.data;
      });
    },

    load() {
      if (this.checiAllList.length > 0) {
        clearInterval(this.cheTimer);
        this.checiNowList = [
          ...this.checiNowList,
          ...this.checiAllList.splice(0, 30)
        ];
      } else {
        this.loading = false;
      }
    },

    // 根据驾驶员、车辆和日期查询车次
    getDingListFn() {
      var self = this;
      var data = {
        workDay: self.form.workTime,
        credentialsNo: self.updateform.oldCredentialsNo,
        vehicleNo: self.updateform.oldVehicleNo
      };
      queryTopShiftChiCiDispatch(data).then(res => {
        self.dingAllList = res.data;
        self.dingAllList.map(item => {
          if (item.moveType == 2) {
            item.moveType = "主-副";
          } else {
            item.moveType = "副-主";
          }
        });
      });
    },

    // 显示可选司机和车辆列表
    checBusDriverByline() {
      var self = this;
      var data = {
        companyId:
          self.updateform.classType == "2"
            ? self.updateform.lineId
            : self.editableTabsValue,
        workTime: self.form.workTime,
        isArranging: self.updateform.updateType,
        cheCiIds: self.updateform.cheCiIds
      };
      self.allDingBusByline = [];
      self.allDriverByline = [];
      queryTopShiftVehicleDispatch(data).then(res => {
        self.allDingBusByline = res.data;
        if (res.data.length == 0) {
          self.$message.warning("当前时间无车辆可选择");
        }
      });
      queryTopShiftDriverDispatch(data).then(res => {
        self.allDriverByline = res.data;
        if (res.data.length == 0) {
          self.$message.warning("当前时间无司机可选择");
        }
      });
    },

    // 添加班次/车次
    addShiftOrTrip() {
      this.addShiftOrTripDia = true;
    },
    // 添加班次/车次弹窗切换班次/车次
    shift2radio() {
      this.LineType=1;
      this.addShiftOrTripForm = {
        line: "",
        date: "",
        vehicle: "",
        type: "1",
        time: "",
        runTime: "",
        restTime: "",
        addTrips: "0.5",
        driver: ""
      };
      this.$refs.runTime.resetField();
      this.vehicleOptions = [];
      this.driversOptions = [];
    },
    // 提交新增班次/车次表单
    submitShiftOrTrip() {
      let data = {
        addTime: moment(new Date()).format("YYYY-MM-DD"),
        beginTime: this.addShiftOrTripForm.time,
        vehicleNo: this.addShiftOrTripForm.vehicle,
        credentialsNo: this.addShiftOrTripForm.driver,
        companyId: this.addShiftOrTripForm.line,
        moveType: this.addShiftOrTripForm.type == 1 ? 2 : 1, // 发车类型，主-副(2),副-主(1)
        restTime: this.addShiftOrTripForm.restTime, // 休息时长，分钟数
        workTime: this.addShiftOrTripForm.runTime // 运行时长，分钟数
      };
      // 添加班次
      if (this.addShiftOrTripRadio == 1) {
        data = {
          ...data,
          addNums: this.addShiftOrTripForm.addTrips
        };
        addBanciDispatch(data).then(res => {
          if (res.code === 1000) {
            this.$message.success("新增班次成功!");
          } else {
            // this.$message.error("新增班次失败!");
            this.$message.error(res.msg);
          }
        });
      }
      // 添加车次
      else {
        addCheciDispatch(data).then(res => {
          if (res.code === 1000) {
            this.$message.success("新增车次成功!");
          } else {
            // this.$message.error("新增车次失败!");
            this.$message.error(res.msg);
          }
        });
      }
      this.cancleAddShiftOrTripDia();
      this.$refs.form.clearValidate();
    },
    // 关闭增加班次/车次弹窗
    cancleAddShiftOrTripDia() {
      this.addShiftOrTripDia = false;
      // 重置弹窗数据
      this.addShiftOrTripRadio = "1";
      this.LineType=1;
      this.addShiftOrTripForm = {
        line: "",
        date: "",
        vehicle: "",
        type: "1",
        time: "",
        runTime: "0",
        restTime: "0",
        addTrips: "0.5",
        driver: ""
      };
      this.vehicleOptions = [];
      this.driversOptions = [];
    },
    // 添加班次/车次选择线路时获取可选车辆信息和司机信息
    chooseLine(e) {
      // this.addShiftOrTripForm.line = e.companyId;
      this.allLines.map(item=>{
        if(item.companyId===e){
          this.LineType = item.lineType
        }
      })
      // console.log(111,e,this.addShiftOrTripForm.line)
      // 在选择线路时,先将表格中车辆和驾驶员清空
      this.addShiftOrTripForm.driver = "";
      this.addShiftOrTripForm.vehicle = "";
      if (this.addShiftOrTripForm.line) {
        const data = {
          companyId: this.addShiftOrTripForm.line
        };
        getBusByline(data).then(res => {
          if (res.code === 1000) {
            this.vehicleOptions = res.data;
          }
        });
        getDriverByline(data).then(res => {
          if (res.code === 1000) {
            this.driversOptions = res.data;
          }
        });
      }
    },

    // 点击监控车辆状态按钮
    onMonitorVehicleStatus() {
      this.monitorVehicleStatus = !this.monitorVehicleStatus;
      this.drawer = !this.drawer;
      this.vehicleStatusData = [];
      // 查询车辆状态列表
      getBusStatus({ companyId: this.editableTabsValue }).then(res => {
        if (res.code === 1000) {
          this.vehicleStatusData = res.data;
          // 如果vehicleStatusData不足5条,则补足5条
          if (this.vehicleStatusData.length < 5) {
            for (let i = 0; i < 5 - this.vehicleStatusData.length + 1; i++) {
              this.vehicleStatusData = [
                ...this.vehicleStatusData,
                {
                  master: { status: -1 },
                  slave: { status: -1 }
                  // color: 1
                }
              ];
            }
          }
        } else {
          // 如果请求失败,则vehecleStatusData补足5条数据
          for (let i = 0; i < 5; i++) {
            this.vehicleStatusData = [
              ...this.vehicleStatusData,
              {
                master: { status: -1 },
                slave: { status: -1 }
                // color: 1
              }
            ];
          }
        }
        this.vehicleStatusData.forEach((item, index) => {
          if (index < 5) {
            item.color = 1;
          }
        });
      });
    },
    // 关闭抽屉的回调
    closeDrawer() {
      this.drawer = false;
      this.vehicleStatusData = [];
    },
    // 打开抽屉的回调
    openDrawer() {
      this.drawer = true;
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: "BUSLX" }).then(res => {
        if (res.code === 1000) {
          this.natureOptions = res.data.BUSLX;
        }
      });
    },
    // 设置车辆状态单元格背景色
    setCellStyle({ row, column }) {
      if (column.label == "主发") {
        switch (row.master.status) {
          case 0:
            return { background: "#2bc505", color: "#fff" };
          case 1:
            return { background: "#848E9F", color: "#fff" };
          case 2:
            return { background: "#FF1212", color: "#fff" };
          case 3:
            return { background: "#0053e1", color: "#fff" };
          case 4:
            return { background: "#FEC800", color: "#fff" };
        }
      }
      if (column.label == "副发") {
        switch (row.slave.status) {
          case 0:
            return { background: "#2bc505", color: "#fff" };
          case 1:
            return { background: "#848E9F", color: "#fff" };
          case 2:
            return { background: "#FF1212", color: "#fff" };
          case 3:
            return { background: "#0053e1", color: "#fff" };
          case 4:
            return { background: "#FEC800", color: "#fff" };
        }
      }
    }
  },
  filters: {
    filterNatureState(item) {
      if (item == 3) {
        return "加油";
      } else if (item == 4) {
        return "加气";
      } else if (item == 5) {
        return "充电";
      } else if (item == 6) {
        return "维修";
      } else if (item == 7) {
        return "包车";
      } else if (item == 128) {
        return "其他";
      } else if (item == 9) {
        return "恢复运营";
      } else {
        return "正常";
      }
    },
    filterAdjustStatus(item) {
      return item == 0 ? "删除" : "正常";
    },
    filterBusStatus(item) {
      if (item == 0) {
        return "未收到";
      } else if (item == 1) {
        return "已收到";
      } else if (item == 2) {
        return "已发车";
      } else if (item == 3) {
        return "已完成";
      } else {
        return "无状态";
      }
    },
    fileterOpstats(item) {
      if (item == 0) {
        return "未处理";
      } else if (item == 1) {
        return "同意";
      } else if (item == 3) {
        return "未处理(已阅)";
      } else {
        return "拒绝";
      }
    },
    filterDingArrive(item) {
      let timestamp =
        new Date(new Date(`2019-01-01 ${item.beginTime}:00`)).getTime() +
        60000 * item.workTime;
      return moment(timestamp).format("HH:mm");
    }
  },
  watch: {
    addShiftOrTripForm: {
      handler(val) {
        // 非空校验
        if (val.line && val.vehicle && val.type && val.time && val.driver) {
          // 添加班次
          if (this.addShiftOrTripRadio == 1) {
            if (val.runTime && val.addTrips) {
              this.isSubmitShiftOrTripVisible = true;
            } else {
              this.isSubmitShiftOrTripVisible = false;
            }
          } else {
            // 添加车次
            if (val.runTime) {
              this.isSubmitShiftOrTripVisible = true;
            } else {
              this.isSubmitShiftOrTripVisible = false;
            }
          }
        } else {
          this.isSubmitShiftOrTripVisible = false;
        }
      },
      deep: true
    }
  },
  created() {
    this.getDicts();
  },
  mounted() {
    this.getFollowLineList();
    this.getAllLineListFn();
    this.timer = setInterval(() => {
      this.getStatistic();
    }, 60000);
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.cheTimer);
  }
};
</script>

<style lang="scss" scoped>
.theme-project-bus {
  .schedulingPlatform {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
    // .plan_div {
    //   flex: 1;
    //   div {
    //     span {
    //       color: #00b9ff;
    //     }
    //   }
    // }
    // .buttons_div {
    //   flex: 2;
    //   text-align: right;
    // }
  }
}
.schedulingPlatform {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  .basicBox {
    .el-table td {
      padding: 8px 0;
    }
    .el-date-editor {
      width: 100px !important;
      height: 33px !important;
    }
  }
  .numberNotice {
    display: block;
    color: #0000ff;
    font-size: 13px;
    width: 500px;
  }
  .plan_div {
    // display: flex;
    // justify-content: space-around;
    font-size: 14px;
    div {
      display: inline-block;
      margin-right: 1vw;
    }
  }
  .el-tabs--card > .el-tabs__header {
    width: 90%;
  }
  .main_con {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
  }
  .bottom {
    flex: 1;
    display: flex;
    .left_con {
      flex: 1;
    }
    .right_con {
      padding-left: 20px;
      flex: 3;
      border: none;
    }
  }
  .bottom_con {
    margin-top: 30px;
  }
  .el-select {
    // width: 100%;
  }
  .addRow {
    position: absolute;
    top: 10px;
  }
  .el-date-editor--datetime {
    width: 280px;
  }
  .el-date-editor {
    height: 28px;
  }
  .dialogTitle {
    font-size: 18px;
    padding: 0 6px 20px 6px;
    color: #666666;
  }
  .el-table >>> td,
  .el-table >>> th {
    padding: 6px 0;
  }
  // .schedulingTab .el-tabs >>> .el-tabs__item{
  //   width: 100px;
  // }
}
.drawer {
  user-select: none;
  .content {
    height: 100%;
    width: 100%;
  }
}
.bottons_div{
  width: 67%;
}
/deep/.status-table {
  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    .status-color {
      display: inline-block;
      height: 20px;
      width: 20px;
      content: "";
      border: 1px solid #ccc;
    }
  }
}
.normal {
  background: #2bc505;
}
.offline {
  background: #848e9f;
}
.maintain {
  background: #ff1212;
}
.refill {
  background: #0053e1;
}
.outline {
  background: #fec800;
}
/deep/.dispatchMonitoring {
  td {
    padding: 4px 0;
  }
}
/deep/.el-tabs--left.el-tabs--card .el-tabs__nav {
  display: flex;
  flex-direction: column;
  padding: 1.5vh;
}
/deep/.el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  width: 7vw;
  margin-bottom: 1vh;
  border: 1px solid;
  border-radius: 20px;
  font-size: 0.5vw;
  padding: 0 10px;
}
/deep/.el-tabs--left .el-tabs__header.is-left {
  height: 70vh;
  width: 9vw;
  margin-top: 5vh !important;
  background: #f5f6f7;
}
/deep/.el-tabs--left.el-tabs--card .el-tabs__nav {
  border-bottom: none;
}
/deep/.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  border: 1px solid;
}
/deeo/.el-tabs--left.el-tabs--card
  .el-tabs__item.is-left.is-active:first-child {
  border-top: 1px solid;
}
/deep/.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active:last-child {
  border-bottom: 1px solid;
}
/deep/.el-tabs--left {
  .el-tabs__header .el-tabs__item {
    height: 3.5vh;
    line-height: 3.5vh;
  }
}
.schedule-information {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .el-table__body-wrapper .cell {
  color: #000 !important;
  font-size: 16px !important;
}
</style>
