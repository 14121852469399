var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedulingPlatform" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "tab",
          attrs: { type: "border-card" },
          model: {
            value: _vm.tabType,
            callback: function($$v) {
              _vm.tabType = $$v
            },
            expression: "tabType"
          }
        },
        _vm._l(_vm.tabOptions, function(item, index) {
          return _c(
            "el-tab-pane",
            {
              key: index,
              attrs: { label: item.alarmType, name: item.alarmName }
            },
            [
              _c(
                "div",
                { staticStyle: { position: "absolute" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.addTab(_vm.editableTabsValue)
                        }
                      }
                    },
                    [_vm._v("添加线路")]
                  )
                ],
                1
              ),
              _vm.tabType == "1"
                ? _c(
                    "div",
                    { staticClass: "schedulingTab" },
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: {
                            "tab-position": "left",
                            type: "card",
                            closable: ""
                          },
                          on: {
                            "tab-remove": _vm.removeTab,
                            "tab-click": _vm.clickTab
                          },
                          model: {
                            value: _vm.editableTabsValue,
                            callback: function($$v) {
                              _vm.editableTabsValue = $$v
                            },
                            expression: "editableTabsValue"
                          }
                        },
                        _vm._l(_vm.editableTabs, function(ele) {
                          return _c(
                            "el-tab-pane",
                            {
                              key: ele.lineId,
                              attrs: { label: ele.lineName, name: ele.lineId }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "schedule-information function-buttons"
                                },
                                [
                                  _c("div", { staticClass: "plan_div" }, [
                                    _c("div", [
                                      _vm._v(" 运营车辆： "),
                                      _c("span", [_vm._v(_vm._s(_vm.busNum))]),
                                      _vm._v("台 ")
                                    ]),
                                    _c("div", [
                                      _vm._v(" 计划车次： "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.totalNums))
                                      ]),
                                      _vm._v("次 ")
                                    ]),
                                    _c("div", [
                                      _vm._v(" 已完成： "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.completedPlanNums))
                                      ]),
                                      _vm._v("次 ")
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "border-right": "none" }
                                      },
                                      [
                                        _vm._v(" 未完成： "),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.incompletePlanNums))
                                        ]),
                                        _vm._v("次 ")
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "bottons_div" },
                                    [
                                      _vm.$store.state.menu.nowMenuList.indexOf(
                                        "消息下发"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                type: "primary",
                                                size: "small"
                                              },
                                              on: { click: _vm.sendMessage }
                                            },
                                            [_vm._v("消息下发")]
                                          )
                                        : _vm._e(),
                                      _vm.$store.state.menu.nowMenuList.indexOf(
                                        "下发行车计划"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                type: "primary",
                                                size: "small"
                                              },
                                              on: { click: _vm.sendPlanText }
                                            },
                                            [_vm._v("下发行车计划")]
                                          )
                                        : _vm._e(),
                                      _vm.$store.state.menu.nowMenuList.indexOf(
                                        "调整计划"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                type: "primary",
                                                size: "small"
                                              },
                                              on: { click: _vm.changeDriver }
                                            },
                                            [_vm._v("调整计划")]
                                          )
                                        : _vm._e(),
                                      _vm.$store.state.menu.nowMenuList.indexOf(
                                        "匀点"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                type: "primary",
                                                size: "small"
                                              },
                                              on: { click: _vm.changeAverage }
                                            },
                                            [_vm._v("匀点")]
                                          )
                                        : _vm._e(),
                                      _vm.$store.state.menu.nowMenuList.indexOf(
                                        "添加班次车次"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                size: "small",
                                                type: "primary"
                                              },
                                              on: { click: _vm.addShiftOrTrip }
                                            },
                                            [_vm._v("添加班次/车次")]
                                          )
                                        : _vm._e(),
                                      _vm.$store.state.menu.nowMenuList.indexOf(
                                        "模拟地图"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                size: "small",
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.mapdrawer = true
                                                }
                                              }
                                            },
                                            [_vm._v("模拟地图")]
                                          )
                                        : _vm._e(),
                                      _vm.$store.state.menu.nowMenuList.indexOf(
                                        "监控车辆状态"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                size: "small",
                                                type: "primary"
                                              },
                                              on: {
                                                click:
                                                  _vm.onMonitorVehicleStatus
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  !_vm.drawer
                                                    ? "监控车辆状态"
                                                    : "取消监控车辆"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-area" },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      staticClass: "demo-form-inline",
                                      attrs: {
                                        inline: true,
                                        model: _vm.form,
                                        "label-position": "left",
                                        "label-width": "90px"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发车类型:",
                                            prop: "moveType"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择发车类型",
                                                clearable: ""
                                              },
                                              on: {
                                                change: _vm.getCheCiListFn
                                              },
                                              model: {
                                                value: _vm.form.moveType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "moveType",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.moveType"
                                              }
                                            },
                                            _vm._l(_vm.moveTypes, function(i) {
                                              return _c("el-option", {
                                                key: i.value,
                                                attrs: {
                                                  label: i.label,
                                                  value: i.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "车牌号:",
                                            prop: "vehicleNo"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择车牌号",
                                                clearable: ""
                                              },
                                              on: {
                                                change: _vm.getCheCiListFn
                                              },
                                              model: {
                                                value: _vm.form.vehicleNo,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "vehicleNo",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.vehicleNo"
                                              }
                                            },
                                            _vm._l(_vm.allBusByline, function(
                                              i
                                            ) {
                                              return _c("el-option", {
                                                key: i.vehicleNo,
                                                attrs: {
                                                  label: i.cph,
                                                  value: i.vehicleNo
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "el-table-infinite-scroll",
                                      rawName: "v-el-table-infinite-scroll",
                                      value: _vm.load,
                                      expression: "load"
                                    }
                                  ],
                                  staticClass: "basicBox dispatchMonitoring",
                                  attrs: {
                                    data: _vm.checiNowList,
                                    height: "65vh",
                                    stripe: "",
                                    "cell-style": {
                                      color: "#000",
                                      fontSize: "16px"
                                    },
                                    "header-cell-style": {
                                      background: "#fff",
                                      color: "#999999"
                                    },
                                    "infinite-scroll-disabled": _vm.loading,
                                    "infinite-scroll-distance": "20"
                                  },
                                  on: { "selection-change": _vm.getCheckedData }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      width: "55",
                                      selectable: _vm.checkboxT,
                                      disabled: "true"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "moveType",
                                      label: "发车类型"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.moveTypes.find(function(
                                                    t
                                                  ) {
                                                    return (
                                                      t.value ===
                                                      scope.row.moveType
                                                    )
                                                  }).label
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "beginTime",
                                      label: "计划发车"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "leaveTime",
                                      label: "实际发车"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "endTime",
                                      label: "计划到达"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "arriveTime",
                                      label: "实际到达"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "workTime",
                                      label: "行驶时长"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "cph",
                                      label: "车牌号",
                                      width: "110"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#01ADFF",
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showScheduling(
                                                        scope.row
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(scope.row.cph))]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "driverName",
                                      label: "计划司机"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "realityDriverName",
                                      label: "实际司机"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "busStatus",
                                      label: "行车状态"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.busStatus == "0"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#FF1212"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "filterBusStatus"
                                                          )(scope.row.busStatus)
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              scope.row.busStatus == "1"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#FFCF0A"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "filterBusStatus"
                                                          )(scope.row.busStatus)
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              scope.row.busStatus == "2"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#0053E1"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "filterBusStatus"
                                                          )(scope.row.busStatus)
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              scope.row.busStatus == "3"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#2BC505"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "filterBusStatus"
                                                          )(scope.row.busStatus)
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              scope.row.busStatus == "4"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "filterBusStatus"
                                                        )(scope.row.busStatus)
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "stationDesc",
                                      label: "当前位置",
                                      width: "180",
                                      "show-overflow-tooltip": true
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "计划状态" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.adjustStatus == 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "red"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "filterAdjustStatus"
                                                            )(
                                                              scope.row
                                                                .adjustStatus
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "filterAdjustStatus"
                                                        )(
                                                          scope.row.adjustStatus
                                                        )
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "natureState",
                                      label: "变更说明"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.natureState == "3" ||
                                              scope.row.natureState == "4" ||
                                              scope.row.natureState == "5"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#0053e1"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          (
                                                            _vm.natureOptions.find(
                                                              function(t) {
                                                                return (
                                                                  t.dictCode ===
                                                                  scope.row
                                                                    .natureState
                                                                )
                                                              }
                                                            ) || {
                                                              dictValue: ""
                                                            }
                                                          ).dictValue
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              scope.row.natureState == "6"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#acb2bc"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          (
                                                            _vm.natureOptions.find(
                                                              function(t) {
                                                                return (
                                                                  t.dictCode ===
                                                                  scope.row
                                                                    .natureState
                                                                )
                                                              }
                                                            ) || {
                                                              dictValue: ""
                                                            }
                                                          ).dictValue
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              scope.row.natureState == "7"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#2dbff7"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          (
                                                            _vm.natureOptions.find(
                                                              function(t) {
                                                                return (
                                                                  t.dictCode ===
                                                                  scope.row
                                                                    .natureState
                                                                )
                                                              }
                                                            ) || {
                                                              dictValue: ""
                                                            }
                                                          ).dictValue
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              scope.row.natureState == "0" ||
                                              scope.row.natureState == "128"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        (
                                                          _vm.natureOptions.find(
                                                            function(t) {
                                                              return (
                                                                t.dictCode ===
                                                                scope.row
                                                                  .natureState
                                                              )
                                                            }
                                                          ) || { dictValue: "" }
                                                        ).dictValue
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "remark", label: "备注" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabType == "2"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: {
                            "tab-position": "left",
                            type: "card",
                            closable: ""
                          },
                          on: {
                            "tab-remove": _vm.removeTab,
                            "tab-click": _vm.clickTab
                          },
                          model: {
                            value: _vm.editableTabsValue,
                            callback: function($$v) {
                              _vm.editableTabsValue = $$v
                            },
                            expression: "editableTabsValue"
                          }
                        },
                        _vm._l(_vm.editableTabs, function(ele) {
                          return _c(
                            "el-tab-pane",
                            {
                              key: ele.lineId,
                              attrs: { label: ele.lineName, name: ele.lineId }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-area" },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      staticClass: "demo-form-inline",
                                      attrs: {
                                        inline: true,
                                        model: _vm.eventform,
                                        "label-position": "left"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "开始时间：",
                                            prop: "startTime"
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              clearable: false,
                                              editable: false,
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              type: "date",
                                              placeholder: "选择日期"
                                            },
                                            model: {
                                              value: _vm.eventform.startTime,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.eventform,
                                                  "startTime",
                                                  $$v
                                                )
                                              },
                                              expression: "eventform.startTime"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "结束时间：",
                                            prop: "endTime"
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              clearable: false,
                                              editable: false,
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              type: "date",
                                              placeholder: "选择日期"
                                            },
                                            model: {
                                              value: _vm.eventform.endTime,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.eventform,
                                                  "endTime",
                                                  $$v
                                                )
                                              },
                                              expression: "eventform.endTime"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "业务类型：",
                                            prop: "eventId"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择业务类型",
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.eventform.eventId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.eventform,
                                                    "eventId",
                                                    $$v
                                                  )
                                                },
                                                expression: "eventform.eventId"
                                              }
                                            },
                                            _vm._l(_vm.natureOptions, function(
                                              i
                                            ) {
                                              return _c("el-option", {
                                                key: i.dictCode,
                                                attrs: {
                                                  label: i.dictValue,
                                                  value: i.dictCode
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "车辆：",
                                            prop: "vehicleNo"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择车牌号",
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.eventform.vehicleNo,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.eventform,
                                                    "vehicleNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "eventform.vehicleNo"
                                              }
                                            },
                                            _vm._l(_vm.allBusByline, function(
                                              i
                                            ) {
                                              return _c("el-option", {
                                                key: i.vehicleNo,
                                                attrs: {
                                                  label: i.cph,
                                                  value: i.vehicleNo
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "处理状态：",
                                            prop: "opStats"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择处理状态",
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.eventform.opStats,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.eventform,
                                                    "opStats",
                                                    $$v
                                                  )
                                                },
                                                expression: "eventform.opStats"
                                              }
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "已同意",
                                                  value: "1"
                                                }
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "已拒绝",
                                                  value: "2"
                                                }
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "未处理",
                                                  value: "0"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "", prop: "busId" } },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                type: "primary",
                                                size: "small"
                                              },
                                              on: { click: _vm.searchEvent }
                                            },
                                            [_vm._v("查询")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-table",
                                {
                                  staticClass: "basicBox",
                                  attrs: {
                                    data: _vm.eventList,
                                    height: "63vh",
                                    stripe: "",
                                    "header-cell-style": {
                                      background: "#fff",
                                      color: "#999999"
                                    }
                                  },
                                  on: { "row-click": _vm.showEventUndeal }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "index",
                                      label: "序号",
                                      width: "100"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "insertTime",
                                      label: "请求时间",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "cph", label: "车辆" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "driverName", label: "司机" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "eventId",
                                      label: "请求业务"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  (
                                                    _vm.natureOptions.find(
                                                      function(t) {
                                                        return (
                                                          t.dictCode ==
                                                          scope.row.eventId
                                                        )
                                                      }
                                                    ) || { dictValue: "" }
                                                  ).dictValue
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "opStats",
                                      label: "处理状态"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("fileterOpstats")(
                                                    scope.row.opStats
                                                  )
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "updateTime",
                                      label: "处理时间",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "operatorName",
                                      label: "处理人"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        }),
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("sendMsg", {
                attrs: { lineId: _vm.editableTabsValue },
                on: { closeDia: _vm.closeDia }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.averagdialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "排班匀点",
                visible: _vm.averagdialogVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.averagdialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form search-area" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "averageform",
                      staticClass: "demo-form dialog-form",
                      attrs: {
                        model: _vm.averageform,
                        "status-icon": "",
                        rules: _vm.averagerules,
                        "label-position": "left",
                        "label-width": "102px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { label: "匀点方向", prop: "direction" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择方向" },
                              model: {
                                value: _vm.averageform.direction,
                                callback: function($$v) {
                                  _vm.$set(_vm.averageform, "direction", $$v)
                                },
                                expression: "averageform.direction"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "向前", value: "1" }
                              }),
                              _c("el-option", {
                                attrs: { label: "向后", value: "2" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { label: "匀点数", prop: "spotCount" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.averageform.spotCount,
                              callback: function($$v) {
                                _vm.$set(_vm.averageform, "spotCount", $$v)
                              },
                              expression: "averageform.spotCount"
                            }
                          }),
                          _c("span", { staticClass: "numberNotice" }, [
                            _vm._v(
                              "操作说明：匀点车次相邻车次的匀点数为1，以此类推匀点数递增"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttonGroup text-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.averagdialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.saveaverage }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.driverdialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "计划调整",
                visible: _vm.driverdialogVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.driverdialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form search-area" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "updateform",
                      staticClass: "demo-form dialog-form",
                      attrs: {
                        model: _vm.updateform,
                        "status-icon": "",
                        rules: _vm.updaterules,
                        "label-position": "left",
                        "label-width": "102px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "type" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: "1",
                                disabled: _vm.updateform.cheCiIds.length > 1
                              },
                              model: {
                                value: _vm.updateform.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.updateform, "type", $$v)
                                },
                                expression: "updateform.type"
                              }
                            },
                            [_vm._v("调整时间")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: "2",
                                disabled: _vm.updateform.cheCiIds.length > 1
                              },
                              model: {
                                value: _vm.updateform.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.updateform, "type", $$v)
                                },
                                expression: "updateform.type"
                              }
                            },
                            [_vm._v("顶班")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "3" },
                              model: {
                                value: _vm.updateform.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.updateform, "type", $$v)
                                },
                                expression: "updateform.type"
                              }
                            },
                            [_vm._v("删除计划")]
                          )
                        ],
                        1
                      ),
                      _vm.updateform.type === "1"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "发车时间:",
                                    prop: "beginTime"
                                  }
                                },
                                [
                                  _c("el-time-picker", {
                                    attrs: {
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                      size: "small",
                                      placeholder: "选择时间"
                                    },
                                    model: {
                                      value: _vm.updateform.beginTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.updateform,
                                          "beginTime",
                                          $$v
                                        )
                                      },
                                      expression: "updateform.beginTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.updateform.type === "2"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "方式", prop: "classType" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择方式" },
                                      on: { change: _vm.checBusDriverByline },
                                      model: {
                                        value: _vm.updateform.classType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.updateform,
                                            "classType",
                                            $$v
                                          )
                                        },
                                        expression: "updateform.classType"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "车人并换", value: "1" }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "司机顶班", value: "2" }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "车辆顶班", value: "3" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "现车辆",
                                    prop: "oldVehicleNo"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.updateform.oldVehicleNo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.updateform,
                                            "oldVehicleNo",
                                            $$v
                                          )
                                        },
                                        expression: "updateform.oldVehicleNo"
                                      }
                                    },
                                    _vm._l(_vm.nowBusByline, function(item) {
                                      return _c("el-option", {
                                        key: item.vehicleNo,
                                        attrs: {
                                          label: item.cph,
                                          value: item.vehicleNo
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "现司机",
                                    prop: "oldCredentialsNo"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.updateform.oldCredentialsNo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.updateform,
                                            "oldCredentialsNo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "updateform.oldCredentialsNo"
                                      }
                                    },
                                    _vm._l(_vm.nowDriverByline, function(item) {
                                      return _c("el-option", {
                                        key: item.credentialsNo,
                                        attrs: {
                                          label: item.driverName,
                                          value: item.credentialsNo
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.updateform.classType != "2"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "顶班车辆",
                                        prop: "newVehicleNo"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.updateform.classType == "2"
                                          },
                                          model: {
                                            value: _vm.updateform.newVehicleNo,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.updateform,
                                                "newVehicleNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateform.newVehicleNo"
                                          }
                                        },
                                        _vm._l(_vm.allDingBusByline, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.vehicleNo,
                                            attrs: {
                                              label: item.cph,
                                              value: item.vehicleNo
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.updateform.classType != "3"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "顶班司机",
                                        prop: "newCredentialsNo"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value:
                                              _vm.updateform.newCredentialsNo,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.updateform,
                                                "newCredentialsNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateform.newCredentialsNo"
                                          }
                                        },
                                        _vm._l(_vm.allDriverByline, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.credentialsNo,
                                            attrs: {
                                              label: item.driverName,
                                              value: item.credentialsNo
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "600px" },
                                  attrs: { label: "车次", prop: "checi" }
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "basicBox",
                                      attrs: {
                                        data: _vm.dingAllList,
                                        stripe: ""
                                      },
                                      on: {
                                        "selection-change":
                                          _vm.getDingCheckedData
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "selection",
                                          width: "55"
                                        }
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "beginTime",
                                          label: "计划发车"
                                        }
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "endTime",
                                          label: "计划到达"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "filterDingArrive"
                                                      )(scope.row)
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2946018485
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "moveType",
                                          label: "发车类型"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.updateform.type === "3"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "变更说明:",
                                    prop: "natureState"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择变更说明" },
                                      model: {
                                        value: _vm.updateform.natureState,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.updateform,
                                            "natureState",
                                            $$v
                                          )
                                        },
                                        expression: "updateform.natureState"
                                      }
                                    },
                                    _vm._l(_vm.natureOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.dictCode,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictCode
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注:", prop: "remark" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.updateform.remark,
                                      callback: function($$v) {
                                        _vm.$set(_vm.updateform, "remark", $$v)
                                      },
                                      expression: "updateform.remark"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttonGroup text-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.driverdialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm.updateform.type === "1"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.changeTime }
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                  _vm.updateform.type === "2"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.update }
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                  _vm.updateform.type === "3"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.submitDelPlan }
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "addShiftOrTripDia",
          attrs: {
            title: "添加班次/车次",
            visible: _vm.addShiftOrTripDia,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.addShiftOrTripDia = $event
            },
            close: _vm.cancleAddShiftOrTripDia,
            open: function($event) {
              _vm.addShiftOrTripDia = true
            }
          }
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.shift2radio },
              model: {
                value: _vm.addShiftOrTripRadio,
                callback: function($$v) {
                  _vm.addShiftOrTripRadio = $$v
                },
                expression: "addShiftOrTripRadio"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "添加班次", name: "1" } }, [
                _c(
                  "div",
                  { staticClass: "form search-area" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "demo-form dialog-form",
                        attrs: {
                          model: _vm.addShiftOrTripForm,
                          "label-position": "left",
                          "label-width": "102px",
                          rules: _vm.addShiftOrTripRules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "线路:", prop: "line" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.chooseLine },
                                model: {
                                  value: _vm.addShiftOrTripForm.line,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "line",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.line"
                                }
                              },
                              _vm._l(_vm.allLines, function(item) {
                                return _c("el-option", {
                                  key: item.companyId,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.companyId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "车辆:", prop: "vehicle" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.filterMethodFun,
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.addShiftOrTripForm.vehicle,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "vehicle",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.vehicle"
                                }
                              },
                              _vm._l(_vm.vehicleOptions, function(item) {
                                return _c("el-option", {
                                  key: item.vehicleNo,
                                  attrs: {
                                    label: item.cph,
                                    value: item.vehicleNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "驾驶员:", prop: "vehicle" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.vehicleMethodFun,
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.addShiftOrTripForm.driver,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "driver",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.driver"
                                }
                              },
                              _vm._l(_vm.driversOptions, function(item) {
                                return _c("el-option", {
                                  key: item.credentialsNo,
                                  attrs: {
                                    label: item.driverName,
                                    value: item.credentialsNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发车类型:", prop: "type" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.addShiftOrTripForm.type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "type",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.type"
                                }
                              },
                              [_vm._v("主-副")]
                            ),
                            _vm.LineType !== 1
                              ? _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "2" },
                                    model: {
                                      value: _vm.addShiftOrTripForm.type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addShiftOrTripForm,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression: "addShiftOrTripForm.type"
                                    }
                                  },
                                  [_vm._v("副-主")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发车时间:", prop: "time" } },
                          [
                            _c("el-time-picker", {
                              attrs: {
                                "value-format": "HH:mm",
                                placeholder: "请选择发车时间"
                              },
                              model: {
                                value: _vm.addShiftOrTripForm.time,
                                callback: function($$v) {
                                  _vm.$set(_vm.addShiftOrTripForm, "time", $$v)
                                },
                                expression: "addShiftOrTripForm.time"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            ref: "runTime",
                            attrs: { label: "运行时长(分钟):", prop: "runTime" }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addShiftOrTripForm.runTime,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addShiftOrTripForm,
                                    "runTime",
                                    $$v
                                  )
                                },
                                expression: "addShiftOrTripForm.runTime"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            ref: "restTime",
                            attrs: {
                              label: "休息时长(分钟):",
                              prop: "restTime"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addShiftOrTripForm.restTime,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addShiftOrTripForm,
                                    "restTime",
                                    $$v
                                  )
                                },
                                expression: "addShiftOrTripForm.restTime"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.addShiftOrTripRadio == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: { label: "增加趟次:", prop: "addTrips" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.addShiftOrTripForm.addTrips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addShiftOrTripForm,
                                          "addTrips",
                                          $$v
                                        )
                                      },
                                      expression: "addShiftOrTripForm.addTrips"
                                    }
                                  },
                                  _vm._l(_vm.addTripsOptions, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("el-tab-pane", { attrs: { label: "添加车次", name: "2" } }, [
                _c(
                  "div",
                  { staticClass: "form search-area" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "demo-form dialog-form",
                        attrs: {
                          model: _vm.addShiftOrTripForm,
                          "label-position": "left",
                          "label-width": "102px",
                          rules: _vm.addShiftOrTripRules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "线路:", prop: "line" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.chooseLine },
                                model: {
                                  value: _vm.addShiftOrTripForm.line,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "line",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.line"
                                }
                              },
                              _vm._l(_vm.allLines, function(item) {
                                return _c("el-option", {
                                  key: item.companyId,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.companyId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "车辆:", prop: "vehicle" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.filterMethodFun,
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.addShiftOrTripForm.vehicle,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "vehicle",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.vehicle"
                                }
                              },
                              _vm._l(_vm.vehicleOptions, function(item) {
                                return _c("el-option", {
                                  key: item.vehicleNo,
                                  attrs: {
                                    label: item.cph,
                                    value: item.vehicleNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "驾驶员:", prop: "vehicle" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.vehicleMethodFun,
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.addShiftOrTripForm.driver,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "driver",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.driver"
                                }
                              },
                              _vm._l(_vm.driversOptions, function(item) {
                                return _c("el-option", {
                                  key: item.credentialsNo,
                                  attrs: {
                                    label: item.driverName,
                                    value: item.credentialsNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发车类型:", prop: "type" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.addShiftOrTripForm.type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "type",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.type"
                                }
                              },
                              [_vm._v("主-副")]
                            ),
                            _vm.LineType !== 1
                              ? _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "2" },
                                    model: {
                                      value: _vm.addShiftOrTripForm.type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addShiftOrTripForm,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression: "addShiftOrTripForm.type"
                                    }
                                  },
                                  [_vm._v("副-主")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "发车时间:", prop: "time" } },
                          [
                            _c("el-time-picker", {
                              attrs: {
                                "value-format": "HH:mm",
                                placeholder: "请选择发车时间"
                              },
                              model: {
                                value: _vm.addShiftOrTripForm.time,
                                callback: function($$v) {
                                  _vm.$set(_vm.addShiftOrTripForm, "time", $$v)
                                },
                                expression: "addShiftOrTripForm.time"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            ref: "runTime",
                            attrs: { label: "运行时长(分钟):", prop: "runTime" }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addShiftOrTripForm.runTime,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addShiftOrTripForm,
                                    "runTime",
                                    $$v
                                  )
                                },
                                expression: "addShiftOrTripForm.runTime"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            ref: "restTime",
                            attrs: {
                              label: "休息时长(分钟):",
                              prop: "restTime"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addShiftOrTripForm.restTime,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addShiftOrTripForm,
                                    "restTime",
                                    $$v
                                  )
                                },
                                expression: "addShiftOrTripForm.restTime"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.addShiftOrTripRadio == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: { label: "增加趟次:", prop: "addTrips" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.addShiftOrTripForm.addTrips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addShiftOrTripForm,
                                          "addTrips",
                                          $$v
                                        )
                                      },
                                      expression: "addShiftOrTripForm.addTrips"
                                    }
                                  },
                                  _vm._l(_vm.addTripsOptions, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttonGroup text-right tabButtonGroup" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: { click: _vm.cancleAddShiftOrTripDia }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: !_vm.isSubmitShiftOrTripVisible
                  },
                  on: { click: _vm.submitShiftOrTrip }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.schedulingVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "单车发班表",
                visible: _vm.schedulingVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.schedulingVisible = $event
                }
              }
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.busPlanList } },
                [
                  _c(
                    "el-table-column",
                    { attrs: { prop: "time", label: _vm.busCode } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: _vm.banCiMoveType == 2 ? "down" : "up",
                          label: _vm.banCiMoveType == 2 ? "主-副" : "副-主"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.downState === 1 &&
                                  _vm.banCiMoveType == 2
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "rgb(1, 173, 255)"
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row.down))]
                                      )
                                    : _vm._e(),
                                  scope.row.downState === 0 &&
                                  _vm.banCiMoveType == 2
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.down))
                                      ])
                                    : _vm._e(),
                                  scope.row.upState === 1 &&
                                  _vm.banCiMoveType == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "rgb(1, 173, 255)"
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row.up))]
                                      )
                                    : _vm._e(),
                                  scope.row.upState === 0 &&
                                  _vm.banCiMoveType == 1
                                    ? _c("span", [_vm._v(_vm._s(scope.row.up))])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2133906868
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: _vm.banCiMoveType == 2 ? "up" : "down",
                          label: _vm.banCiMoveType == 2 ? "副-主" : "主-副"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.downState === 1 &&
                                  _vm.banCiMoveType == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "rgb(1, 173, 255)"
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row.down))]
                                      )
                                    : _vm._e(),
                                  scope.row.downState === 0 &&
                                  _vm.banCiMoveType == 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.down))
                                      ])
                                    : _vm._e(),
                                  scope.row.upState === 1 &&
                                  _vm.banCiMoveType == 2
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "rgb(1, 173, 255)"
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row.up))]
                                      )
                                    : _vm._e(),
                                  scope.row.upState === 0 &&
                                  _vm.banCiMoveType == 2
                                    ? _c("span", [_vm._v(_vm._s(scope.row.up))])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3585944564
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttonGroup text-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.schedulingVisible = false
                        }
                      }
                    },
                    [_vm._v("关闭")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.followVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "新增关注线路",
                visible: _vm.followVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.followVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form search-area" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticClass: "demo-form dialog-form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-position": "left",
                        "label-width": "102px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "线路", prop: "lineId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择线路" },
                              model: {
                                value: _vm.form.lineId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "lineId", $$v)
                                },
                                expression: "form.lineId"
                              }
                            },
                            _vm._l(_vm.allLines, function(item) {
                              return _c("el-option", {
                                key: item.companyId,
                                attrs: {
                                  label: item.companyName,
                                  value: item.companyId
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttonGroup text-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.followVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.saveFollowLine }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.mapdrawer
        ? _c(
            "el-drawer",
            {
              attrs: {
                visible: _vm.mapdrawer,
                "with-header": false,
                "custom-class": "drawer",
                modal: false,
                direction: "btt",
                size: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.mapdrawer = $event
                },
                close: _vm.closeDrawer,
                open: _vm.openDrawer
              }
            },
            [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("simulationMap", {
                    staticStyle: { "margin-top": "7vh" },
                    attrs: { isAllSimulate: true, lineId: _vm.querryLineId }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.drawer,
            "with-header": false,
            "custom-class": "drawer",
            modal: false,
            size: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.drawer = $event
            },
            close: _vm.closeDrawer,
            open: _vm.openDrawer
          }
        },
        [
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "vehicle-status" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "status-table",
                    attrs: {
                      data: _vm.vehicleStatusData,
                      "cell-style": _vm.setCellStyle
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "颜色", width: "100" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return scope.row.color
                                ? [
                                    _c("i", {
                                      class: {
                                        "status-color": true,
                                        normal: scope.$index == 0,
                                        offline: scope.$index == 1,
                                        maintain: scope.$index == 2,
                                        refill: scope.$index == 3,
                                        outline: scope.$index == 4
                                      }
                                    }),
                                    scope.$index == 0
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [_vm._v("正常")]
                                        )
                                      : _vm._e(),
                                    scope.$index == 1
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [_vm._v("离线")]
                                        )
                                      : _vm._e(),
                                    scope.$index == 2
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [_vm._v("维修")]
                                        )
                                      : _vm._e(),
                                    scope.$index == 3
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [_vm._v("加气")]
                                        )
                                      : _vm._e(),
                                    scope.$index == 4
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [_vm._v("脱线")]
                                        )
                                      : _vm._e()
                                  ]
                                : undefined
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c(
                      "el-table-column",
                      { attrs: { label: "车辆状态" } },
                      [
                        _c("el-table-column", {
                          attrs: { label: "主发", prop: "master.cph" }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "副发", prop: "slave.cph" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }